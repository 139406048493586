import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_POPUP,
  GET_WORKSHOP,
  GET_CourseSchedulesMega,
  GET_WorkshopSessionMega,
  GET_WorkshopSessionMega_Success,
  GET_TeacherMega,
  GET_EVENT,
  GET_DYNAMICFORM,
  GET_SLIDER,
  GET_PROJECTSNEW,
  GET_NEWS,
  GET_MEGA,
  GET_CONTACT,
  GET_AUTHORITY,
  GET_WORKSHOP2,
  GET_WORKSHOP2_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_REFERANCES,
  ADD_REFERANCES,
  GET_SUBPROJECT,

  UPDATE_WORKSHOP2,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_AUTH,
  UPDATE_CONTACT,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_GALLERY,
  ADD_LESSONS_TIME,

  UPDATE_LESSONS_TIME,
  GET_TEACHER_WORKSHOP,
  GET_REFERANCES_SUCCESS,
  ADD_WORKSHOP2,
  ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN,
  ADD_COURSE,
  ADD_MEGA,
  ADD_NEWS,
  ADD_PROJECT,
  ADD_SLIDER,
  ADD_POPUP,
  UPDATE_NEWS,
  UPDATE_PROJECT,
  UPDATE_SLIDER,
  UPDATE_TEACHER,
  UPDATE_WORKSHOPSESSION,
  UPDATE_COURSESCHEDULES,
  UPDATE_SUBDOMAIN,
  UPDATE_POPUP,
  GET_POPUP_SUCCESS,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_PROJECT,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_MEGA,
  UPDATE_SUCCESS_GALLERY,

  UPDATE_REFERANCES,
  UPDATE_REFERANCES_SUCCESS,

  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,

  ADD_LESSONSTIME_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,

  GET_CLASSATTENDANCE,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMIC_FORM,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORM_EXTRA,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORM_EXTRA_EXTRA,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,

  DELETE_MEGA,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,

  DELETE_NEWS_SUCCESS,
  DELETE_NEWS,

  DELETE_SLIDER,
  DELETE_SLIDER_SUCCESS,

  DELETE_POPUP,
  DELETE_POPUP_SUCCESS,

  DELETE_REFERANCES,
  DELETE_REFERANCES_SUCCESS,

  ADD_GALLERY,
  ADD_GALLERY_SUCCESS,

  DELETE_GALLERY,
  DELETE_GALLERY_SUCCESS,

  ADD_GALLERYMULTIPLE,
  ADD_GALLERYMULTIPLE_SUCCESS,

  DELETE_COURSESCHEDULES,
  DELETE_COURSESCHEDULES_SUCCESS,

  DELETE_DYNAMICFORMEXTRA,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,

  DELETE_DYNAMICFORM,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  DELETE_DYNAMICFORMEXTRAEXTRA,

  DELETE_SUBDOMAIN,
  DELETE_SUBDOMAIN_SUCCESS,

  ADD_SUBDOMAIN_SUCCESS,

  ADD_NEWTEACHER,
  ADD_NEWTEACHER_SUCCESS,

  DELETE_TEACHER,
  DELETE_TEACHER_SUCCESS,

  DELETE_SUBPROJECT,
  DELETE_SUBPROJECT_SUCCESS,

  ADD_SUBPROJECT,
  ADD_SUBPROJECT_SUCCESS,

  UPDATE_SUBPROJECT,
  UPDATE_SUBPROJECT_SUCCESS,

  ADD_AUTH,
  ADD_AUTH_SUCCESS,

  UPDATE_POPUP_SUCCESS,

  DELETE_USERTYPE_SUCCESS,
  DELETE_USERTYPE,

  ADD_DYNAMIC_FORM2,
  ADD_DYNAMICFORM_SUCCESS2,

  ADD_DYNAMIC_FORM3,
  ADD_DYNAMICFORM_SUCCESS3,

  ADD_APPSETTING_SUCCESS,
  DELETE_WORKSHOPSESSION,
  DELETE_WORKSHOPSESSION_SUCCESS,
  ADD_WORKSHOP2_SUCCESS,

  DELETE_WORKSHOP2_SUCCESS,
  DELETE_WORKSHOP2,
  ADD_COURSEMEGA_SUCCESS,
  DELETE_LESSONSTIME,
DELETE_LESSONSTIME_SUCCESS,
ADD_SLIDER_SUCCESS,

ADD_TEACHERWORKSHOP,
ADD_TEACHERWORKSHOP_SUCCESS,

GET_TEACHERSESSIONMEGA,
GET_TEACHERSESSIONMEGA_SUCCESS,

GET_ALLWORKSHOPMEGA,
GET_ALLWORKSHOPMEGA_SUCCESS,

GET_LOCATION,
GET_LOCATION_SUCCESS,
ADD_LOCATION,
ADD_LOCATION_SUCCESS,
UPDATE_LOCATION,
UPDATE_LOCATION_SUCCESS,
DELETE_LOCATION,
DELETE_LOCATION_SUCCESS,

DELETE_CLASSATTANCE,
DELETE_CLASSATTANCE_SUCCESS,

GET_USERDYNAMICFORM_SUCCESS,
GET_USERDYNAMICFORM,

UPDATE_DYNAMICFORM2,
UPDATE_DYNAMICFORM2_SUCCESS,

DELETE_DYNAMICFORMUSER,
DELETE_DYNAMICFORMUSER_SUCCESS,

UPDATE_FORMSUCCESSUSER,
UPDATE_FORMSUCCESSUSER_SUCCESS,

GET_ALLDYNAMICFORM,
GET_ALLDYNAMICFORM_SUCCESS,

GET_USERDYNAMICFORM_NULL,
GET_USERDYNAMICFORMNULL_SUCCESS,

UPDATE_FORMSUCCESSUSER_NULL,
UPDATE_FORMSUCCESSUSERNULL_SUCCESS,

GET_USERNULL,
GET_USERNULL_SUCCESS,

GET_USERCLASS,
GET_USERCLASS_SUCCESS,

GETTSDASHBOARD,
GETTSDASHBOARD_SUCCESS,

GET_USERPAYMENT,
GET_USERPAYMENT_SUCCESS,
DELETE_PROJECT,
DELETE_PROJECT_SUCCESS,
ADD_PROJECT_SUCCESS,
ADD_POPUP_SUCCESS,
UPDATE_COURSESCHEDULES_SUCCESS,
DELETE_PDF_SUCCESS,
DELETE_PDF,
GET_PDF_SUCCESS,
ADD_PDF,
ADD_PDF_SUCCESS,
UPDATE_PDF,
UPDATE_PDF_SUCCESS,
GET_PDF
} from "./actionTypes"

export const getPdf = teacher => ({
  type: GET_PDF,
  payload: teacher,
})


export const getPdfSuccess = teacher => ({
  type: GET_PDF_SUCCESS,
  payload: teacher,
})


export const deletePdf = user => ({
  type: DELETE_PDF,
  payload: user,
})

export const deletePdfSuccess = (user,user2) => ({
  type: DELETE_PDF_SUCCESS,
  payload: user2,
})


export const addPdf = user => ({
  type: ADD_PDF,
  payload: user,
})


export const addPdfSuccess = user => ({
  type: ADD_PDF_SUCCESS,
  payload: user,
})

export const updatePdf = user => ({
  type: UPDATE_PDF,
  payload: user,
})

export const updatePdfSuccess = user => ({
  type: UPDATE_PDF_SUCCESS,
  payload: user,
})

export const getTSDashboard = teacher => ({
  type: GETTSDASHBOARD,
  payload: teacher,
})

export const getTSDashboardSuccess = teacher => ({
  type: GETTSDASHBOARD_SUCCESS,
  payload: teacher,
})


export const getUserNull = teacher => ({
  type: GET_USERNULL,
  payload: teacher,
})

export const getUserNullSuccess = teacher => ({
  type: GET_USERNULL_SUCCESS,
  payload: teacher,
})



export const getUserClass = teacher => ({
  type: GET_USERCLASS,
  payload: teacher,
})


export const getUserClassSuccess = teacher => ({
  type: GET_USERCLASS_SUCCESS,
  payload: teacher,
})


export const getUserDynamicFormNull = teacher => ({
  type: GET_USERDYNAMICFORM_NULL,
  payload: teacher,
})


export const getUserDynamicFormNullSuccess = teacher => ({
  type: GET_USERDYNAMICFORMNULL_SUCCESS,
  payload: teacher,
})


export const deleteProject1 = user => ({
  type: DELETE_PROJECT,
  payload: user,
})

export const deleteProjectSuccess1 = user => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: user,
})


export const deleteLocation = user => ({
  type: DELETE_LOCATION,
  payload: user,
})

export const deleteLocationSuccess = user => ({
  type: DELETE_LOCATION_SUCCESS,
  payload: user,
})


export const addLocationSuccess = user => ({
  type: ADD_LOCATION_SUCCESS,
  payload: user,
})

export const addLocation = user => ({
  type: ADD_LOCATION,
  payload: user,
})


export const addMegaSuccess = user => ({
  type: ADD_MEGA_SUCCESS,
  payload: user,
})


export const updateFormSuccessUserNull = user => ({
  type: UPDATE_FORMSUCCESSUSER_NULL,
  payload: user,
})

export const updateFormSuccessUserNullSuccess = user => ({
  type: UPDATE_FORMSUCCESSUSERNULL_SUCCESS,
  payload: user,
})


export const updateFormSuccessUser = user => ({
  type: UPDATE_FORMSUCCESSUSER,
  payload: user,
})


export const updateFormSuccessUserSuccess = user => ({
  type: UPDATE_FORMSUCCESSUSER_SUCCESS,
  payload: user,
})


export const updateynamicForm2 = user => ({
  type: UPDATE_DYNAMICFORM2,
  payload: user,
})


export const updateynamicForm2Success = user => ({
  type: UPDATE_DYNAMICFORM2_SUCCESS,
  payload: user,
})


export const updateLocation = user => ({
  type: UPDATE_LOCATION,
  payload: user,
})


export const updateLocationSuccess = user => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload: user,
})

export const updateSubProject = user => ({
  type: UPDATE_SUBPROJECT,
  payload: user,
})


export const updateSubProjectSuccess = user => ({
  type: UPDATE_SUBPROJECT_SUCCESS,
  payload: user,
})

export const updateDynamicFormSuccess = user => ({
  type: UPDATE_DYNAMICFORM_SUCCESS,
  payload: user,
})


export const updateDynamicForm = user => ({
  type: UPDATE_DYNAMICFORM,
  payload: user,
})

export const getClasses = () => ({
  type: GET_CLASSES,
})



export const getDynamicFormExtraExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
})


export const getDynamicFormExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
})


export const getClassesSuccess = user => ({
  type: GET_CLASSES_SUCCESS,
  payload: user,
})

export const getClassAttendanceSuccess = user => ({
  type: GET_CLASSATTENDANCE_SUCCESS,
  payload: user,
})


export const updateLessonsTimeSuccess = user => ({
  type: UPDATE_LESSONSTIME_SUCCESS,
  payload: user,
})


export const getTeacherWorkshopSuccess = teacher => ({
  type: GET_TEACHERWORKSHOP_SUCCESS,
  payload: teacher,
})

export const getSubProjectSuccess = teacher => ({
  type: GET_SUBPROJECT_SUCCESS,
  payload: teacher,
})


export const getReferancesSuccess = teacher => ({
  type: GET_REFERANCES_SUCCESS,
  payload: teacher,
})


export const updateGallerySuccess = user => ({
  type: UPDATE_SUCCESS_GALLERY,
  payload: user,
})


export const getAuthority = () => ({
  type: GET_AUTHORITY,
})



export const updateWorkShop2Success = user => ({
  type: UPDATE_WORKSHOP2_SUCCESS,
  payload: user,
})

export const updateReferances = user => ({
  type: UPDATE_REFERANCES,
  payload: user,
})
export const updateMega = user => ({
  type: UPDATE_MEGA,
  payload: user,
})

export const updateMegaSuccess = news => ({
  type: UPDATE_SUCCESS_MEGA,
  payload: news,
})

export const updateNewsSuccess = news => ({
  type: UPDATE_SUCCESS_NEWS,
  payload: news,
})

export const updateSuccessProject = teacher => ({
  type: UPDATE_SUCCESS_PROJECT,
  payload: teacher,
})

export const updateSliderSuccess = teacher => ({
  type: UPDATE_SUCCESS_SLIDER,
  payload: teacher,
})


export const updateTeacherSuccess = teacher => ({
  type: UPDATE_SUCCESS_TEACHER,
  payload: teacher,
})

export const updateSuccessCourseSchedules = teacher => ({
  type: UPDATE_SUCCESS_COURSESCHEDULES,
  payload: teacher,
})


export const getUserPayment = teacher => ({
  type: GET_USERPAYMENT,
  payload: teacher,
})

export const getUserPaymentSuccess = teacher => ({
  type: GET_USERPAYMENT_SUCCESS,
  payload: teacher,
})



export const getAllDynamicForm = teacher => ({
  type: GET_ALLDYNAMICFORM,
  payload: teacher,
})

export const getAllDynamicFormSuccess = teacher => ({
  type: GET_ALLDYNAMICFORM_SUCCESS,
  payload: teacher,
})

export const getUserDynamicForm = teacher => ({
  type: GET_USERDYNAMICFORM,
  payload: teacher,
})


export const getUserDynamicFormSuccess = teacher => ({
  type: GET_USERDYNAMICFORM_SUCCESS,
  payload: teacher,
})

export const getTeacherSessionMegaSuccess = teacher => ({
  type: GET_TEACHERSESSIONMEGA_SUCCESS,
  payload: teacher,
})

export const getTeacherSessionMega = teacher => ({
  type: GET_TEACHERSESSIONMEGA,
  payload: teacher,
})

export const getAuthUpdateSuccess = teacher => ({
  type: GET_AUTHUPDATE_SUCCESS,
  payload: teacher,
})

export const getAuthSuccess = teacher => ({
  type: GET_AUTH_SUCCESS,
  payload: teacher,
})
export const getDynamicSuccess = teacher => ({
  type: GET_DYNAMIC_SUCCESS,
  payload: teacher,
})

export const getTeacherSuccess = teacher => ({
  type: GET_TEACHER_SUCCESS,
  payload: teacher,
})

export const getGallerySuccess = mega => ({
  type: GET_GALLERY_SUCCESS,
  payload: mega,
})


export const getMegaSuccess = mega => ({
  type: GET_MEGA_SUCCESS,
  payload: mega,
})

export const getLocationFormSuccess = news => ({
  type: GET_LOCATION_SUCCESS,
  payload: news,
})


export const getLocationForm = news => ({
  type: GET_LOCATION,
  payload: news,
})


export const getWorkShop2 = news => ({
  type: GET_WORKSHOP2,
  payload: news,
})


export const getNewsSuccess = news => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
})

export const getProjectSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getSliderSuccess = slider => ({
  type: GET_SLIDER_SUCCESS,
  payload: slider,
})

export const getApplicationSuccess = workshop => ({
  type: GET_APPLICATION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSessionSuccess = workshop => ({
  type: GET_WORKSHOPSESSION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSuccess = workshop => ({
  type: GET_WORKSHOP_SUCCESS,
  payload: workshop,
})


export const updateWorkShopSessionSuccess = user => ({
  type: UPDATE_WORKSHOPSESSION_SUCCESS,
  payload: user,
})

export const updateSubdomainSuccess = user => ({
  type: UPDATE_SUBDOMAIN_SUCCESS,
  payload: user,
})

export const updateAuth = user => ({
  type: UPDATE_AUTH,
  payload: user,
})

export const updateContact = user => ({
  type: UPDATE_CONTACT,
  payload: user,
})

export const updateNews = user => ({
  type: UPDATE_NEWS,
  payload: user,
})


export const updateProject = user => ({
  type: UPDATE_PROJECT,
  payload: user,
})

export const updateSlider = user => ({
  type: UPDATE_SLIDER,
  payload: user,
})


export const getWorkshop2Success = popup => ({
  type: GET_WORKSHOP2_SUCCESS,
  payload: popup,
})


export const getPopupSuccess = popup => ({
  type: GET_POPUP_SUCCESS,
  payload: popup,
})


export const updateTeacher = user => ({
  type: UPDATE_TEACHER,
  payload: user,
})


export const updateWorkshop2 = user => ({
  type: UPDATE_WORKSHOP2,
  payload: user,
})


export const updateWorkshopSession = user => ({
  type: UPDATE_WORKSHOPSESSION,
  payload: user,
})



export const updateCourseSchedules = user => ({
  type: UPDATE_COURSESCHEDULES,
  payload: user,
})

export const updateCourseSchedulesSuccess = news => ({
  type: UPDATE_COURSESCHEDULES_SUCCESS,
  payload: news,
})

export const updateSubdomain = user => ({
  type: UPDATE_SUBDOMAIN,
  payload: user,
})


export const updatePopup = user => ({
  type: UPDATE_POPUP,
  payload: user,
})


export const getClassAttendance = users => ({
  type: GET_CLASSATTENDANCE,
  users
})




export const getReferances = () => ({
  type: GET_REFERANCES,
})

export const getContactMega = () => ({
  type: GET_CONTACT,
})

export const getMegaMega = () => ({
  type: GET_MEGA,
})

export const getNews = () => ({
  type: GET_NEWS,
})


export const getTeacherWorkshopMega = news => ({
  type: GET_TEACHER_WORKSHOP,
  news,
})

export const getAllWorkshopMega = news => ({
  type: GET_ALLWORKSHOPMEGA,
  news,
})

export const getAllWorkshopMegaSuccess = news => ({
  type: GET_ALLWORKSHOPMEGA_SUCCESS,
  news,
})


export const getSubProject = news => ({
  type: GET_SUBPROJECT,
  news,
})


export const getProjectsNew = () => ({
  type: GET_PROJECTSNEW,
})


export const getSlider = () => ({
  type: GET_SLIDER,
})

export const getDynamicForm = () => ({
  type: GET_DYNAMICFORM,
})


export const getEventMega = () => ({
  type: GET_EVENT,
})

export const getTeacherMega = () => ({
  type: GET_TeacherMega,
})

export const getWorkshopSessionMega = () => ({
  type: GET_WorkshopSessionMega,
})

export const getWorkshopSessionMegaSuccess = () => ({
  type: GET_WorkshopSessionMega_Success,
  payload: users,
})

export const getWorkShop = () => ({
  type: GET_WORKSHOP,
})

export const getPopup = () => ({
  type: GET_POPUP,
})

export const getCourseSchedulesMega = () => ({
  type: GET_CourseSchedulesMega,
})



export const getGallery = () => ({
  type: GET_GALLERY,
})

export const getUsers = () => ({
  type: GET_USERS,
})

export const getDynamicFormExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA,
  users,
})

export const getDynamicFormExtraExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA_EXTRA,
  users,
})


export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})


export const addTeacherWorkshopSuccess = user => ({
  type: ADD_TEACHERWORKSHOP_SUCCESS,
  payload: user,
})

export const addTeacherWorkshop = user => ({
  type: ADD_TEACHERWORKSHOP,
  payload: user,
})



export const addDynamicForm3Success = user => ({
  type: ADD_DYNAMICFORM_SUCCESS3,
  payload: user,
})

export const addDynamicForm3 = user => ({
  type: ADD_DYNAMIC_FORM3,
  payload: user,
})

export const addDynamicFormSuccess = user => ({
  type: ADD_DYNAMICFORM_SUCCESS,
  payload: user,
})

export const addDynamicForm = user => ({
  type: ADD_DYNAMIC_FORM,
  payload: user,
})

export const addLessonsTime = user => ({
  type: ADD_LESSONS_TIME,
  payload: user,
})

export const addReferances = user => ({
  type: ADD_REFERANCES,
  payload: user,
})

export const updateSuccessReferances = user => ({
  type: UPDATE_REFERANCES_SUCCESS,
  payload: user,
})

export const addNewWorkshop2 = user => ({
  type: ADD_WORKSHOP2,
  payload: user,
})


export const addAppSetting = user => ({
  type: ADD_APPSETTING,
  payload: user,
})


export const addAppSettingSuccess = user => ({
  type: ADD_APPSETTING_SUCCESS,
  payload: user,
})


export const addWorkshopSubdomain = user => ({
  type: ADD_WORKSHOPSUBDOMAIN,
  payload: user,
})

export const addCourse = user => ({
  type: ADD_COURSE,
  payload: user,
})

export const addNewMega = user => ({
  type: ADD_MEGA,
  payload: user,
})

export const addNews = user => ({
  type: ADD_NEWS,
  payload: user,
})

export const addProject = user => ({
  type: ADD_PROJECT,
  payload: user,
})

export const addProjectSuccess1 = user => ({
  type: ADD_PROJECT_SUCCESS,
  payload: user,
})


export const addSliderSuccess = user => ({
  type: ADD_SLIDER_SUCCESS,
  payload: user,
})

export const addSlider = user => ({
  type: ADD_SLIDER,
  payload: user,
})

export const addPopup = user => ({
  type: ADD_POPUP,
  payload: user,
})

export const addPopupSuccess = user => ({
  type: ADD_POPUP_SUCCESS,
  payload: user,
})


export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})


export const addCourseMegaSuccess = user => ({
  type: ADD_COURSEMEGA_SUCCESS,
  payload: user,
})

export const addSuccessLessonsTime = user => ({
  type: ADD_LESSONSTIME_SUCCESS,
  payload: user,
})


export const addSuccessReferances = user => ({
  type: ADD_REFERANCES_SUCCESS,
  payload: user,
})


export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateLessonsTime = user => ({
  type: UPDATE_LESSONS_TIME,
  payload: user,
})

export const updateGallery = user => ({
  type: UPDATE_GALLERY,
  payload: user,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteDynamicFormSuccessUser = user => ({
  type: DELETE_DYNAMICFORMUSER,
  payload: user,
})

export const deleteDynamicFormSuccessUserSuccess = user => ({
  type: DELETE_DYNAMICFORMUSER_SUCCESS,
  payload: user,
})


export const deleteClassAttanance = user => ({
  type: DELETE_CLASSATTANCE,
  payload: user,
})

export const deleteClassAttananceSuccess = user => ({
  type: DELETE_CLASSATTANCE_SUCCESS,
  payload: user,
})


export const deleteLessonsTime = user => ({
  type: DELETE_LESSONSTIME,
  payload: user,
})

export const deleteLessonsTimeSuccess = user => ({
  type: DELETE_LESSONSTIME_SUCCESS,
  payload: user,
})


export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})


export const deleteMega = user => ({
  type: DELETE_MEGA,
  payload: user,
})

export const deleteMegaSuccess = (user,ID) => ({
  type: DELETE_MEGA_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteNews = user => ({
  type: DELETE_NEWS,
  payload: user,
})

export const deleteNewsSuccess = (user,ID) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteSlider = user => ({
  type: DELETE_SLIDER,
  payload: user,
})

export const deleteSliderSuccess = (user,ID) => ({
  type: DELETE_SLIDER_SUCCESS,
  payload: user,
  ID:ID
})

export const deletePopup = user => ({
  type: DELETE_POPUP,
  payload: user,
})

export const deletePopupSuccess = (user,ID) => ({
  type: DELETE_POPUP_SUCCESS,
  payload: user,
  ID:ID
})


export const deleteReferances = user => ({
  type: DELETE_REFERANCES,
  payload: user,
})

export const deleteReferancesSuccess = (user,ID) => ({
  type: DELETE_REFERANCES_SUCCESS,
  payload: user,
  ID:ID
})

export const addGallerySuccess = user => ({
  type: ADD_GALLERY_SUCCESS,
  payload: user,
})


export const addGallery = user => ({
  type: ADD_GALLERY,
  payload: user,
})

export const deleteGallery = user => ({
  type: DELETE_GALLERY,
  payload: user,
})

export const deleteGallerySuccess = (user,ID) => ({
  type: DELETE_GALLERY_SUCCESS,
  payload: user,
  ID:ID
})


export const addGalleryMultipleSuccess = user => ({
  type: ADD_GALLERYMULTIPLE_SUCCESS,
  payload: user,
})

export const addGalleryMultiple = user => ({
  type: ADD_GALLERYMULTIPLE,
  payload: user,
})

export const deleteCourseSchedules = user => ({
  type: DELETE_COURSESCHEDULES,
  payload: user,
})

export const deleteCourseSchedulesSuccess = (user,ID) => ({
  type: DELETE_COURSESCHEDULES_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteDynamicFormExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRA,
  payload: user,
})

export const deleteDynamicExtraFormSuccess = (user,ID) => ({
  type: DELETE_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteDynamicForm = user => ({
  type: DELETE_DYNAMICFORM,
  payload: user,
})

export const deleteDynamicFormSuccess = (user,ID) => ({
  type: DELETE_DYNAMICFORM_SUCCESS,
  payload: user,
  ID:ID
})



export const deleteDynamicFormExtraExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA,
  payload: user,
})

export const deleteDynamicExtraExtraFormSuccess = (user,ID) => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
  ID:ID
})

export const deleteSubDomain = user => ({
  type: DELETE_SUBDOMAIN,
  payload: user,
})


export const deleteSubDomainSuccess = user => ({
  type: DELETE_SUBDOMAIN_SUCCESS,
  payload: user,
})


export const addSubdomainSuccess = user => ({
  type: ADD_SUBDOMAIN_SUCCESS,
  payload: user,
})

export const addNewTeacher = user => ({
  type: ADD_NEWTEACHER,
  payload: user,
})

export const addNewTeacherSuccess = user => ({
  type: ADD_NEWTEACHER_SUCCESS,
  payload: user,
})

export const deleteTeacher = user => ({
  type: DELETE_TEACHER,
  payload: user,
})

export const deleteTeacherSuccess = user => ({
  type: DELETE_TEACHER_SUCCESS,
  payload: user,
})


export const deleteSubProject = user => ({
  type: DELETE_SUBPROJECT,
  payload: user,
})

export const deleteSubProjectSuccess = user => ({
  type: DELETE_SUBPROJECT_SUCCESS,
  payload: user,
})


export const addSubProject = user => ({
  type: ADD_SUBPROJECT,
  payload: user,
})

export const addSubProjectSuccess = user => ({
  type: ADD_SUBPROJECT_SUCCESS,
  payload: user,
})



export const addNewAuthSuccess = user => ({
  type: ADD_AUTH_SUCCESS,
  payload: user,
})

export const addNewAuth = user => ({
  type: ADD_AUTH,
  payload: user,
})

export const updatePopupSuccess = user => ({
  type: UPDATE_POPUP_SUCCESS,
  payload: user,
})


export const deleteUserType = user => ({
  type: DELETE_USERTYPE,
  payload: user,
})

export const deleteUserTypeSuccess = user => ({
  type: DELETE_USERTYPE_SUCCESS,
  payload: user,
})



export const addDynamicFormSuccess2 = user => ({
  type: ADD_DYNAMICFORM_SUCCESS2,
  payload: user,
})

export const addDynamicForm2 = user => ({
  type: ADD_DYNAMIC_FORM2,
  payload: user,
})

export const deleteWorkshopSession = user => ({
  type: DELETE_WORKSHOPSESSION,
  payload: user,
})

export const deleteWorkshopSessionSuccess = user => ({
  type: DELETE_WORKSHOPSESSION_SUCCESS,
  payload: user,
})

export const addWorkshop2Success = user => ({
  type: ADD_WORKSHOP2_SUCCESS,
  payload: user,
})


export const deleteWorkshop2 = user => ({
  type: DELETE_WORKSHOP2,
  payload: user,
})

export const deleteWorkshop2Success = user => ({
  type: DELETE_WORKSHOP2_SUCCESS,
  payload: user,
})
