export const UPDATE_PDF_SUCCESS = "UPDATE_PDF_SUCCESS"
export const UPDATE_PDF = "UPDATE_PDF"

export const ADD_PDF_SUCCESS = "ADD_PDF_SUCCESS"
export const ADD_PDF = "ADD_PDF"

export const DELETE_PDF_SUCCESS = "DELETE_PDF_SUCCESS"
export const DELETE_PDF = "DELETE_PDF"

export const GET_PDF_SUCCESS = "GET_PDF_SUCCESS"
export const GET_PDF = "GET_PDF"


export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"

export const GET_USERPAYMENT = "GET_USERPAYMENT"
export const GET_USERPAYMENT_SUCCESS = "GET_USERPAYMENT_SUCCESS"

export const GET_WorkshopSessionMega_Success = "GET_WorkshopSessionMega_Success"

export const GET_USERCLASS = "GET_USERCLASS"
export const GET_USERCLASS_SUCCESS = "GET_USERCLASS_SUCCESS"

export const GETTSDASHBOARD = "GETTSDASHBOARD"
export const GETTSDASHBOARD_SUCCESS = "GETTSDASHBOARD_SUCCESS"

export const GET_USERNULL = "GET_USERNULL"
export const GET_USERNULL_SUCCESS = "GET_USERNULL_SUCCESS"

export const UPDATE_FORMSUCCESSUSER_NULL = "UPDATE_FORMSUCCESSUSER_NULL"
export const UPDATE_FORMSUCCESSUSERNULL_SUCCESS = "UPDATE_FORMSUCCESSUSERNULL_SUCCESS"


export const GET_USERDYNAMICFORM_NULL = "GET_USERDYNAMICFORM_NULL"
export const GET_USERDYNAMICFORMNULL_SUCCESS = "GET_USERDYNAMICFORMNULL_SUCCESS"

export const GET_ALLDYNAMICFORM = "GET_ALLDYNAMICFORM"
export const GET_ALLDYNAMICFORM_SUCCESS = "GET_ALLDYNAMICFORM_SUCCESS"

export const UPDATE_FORMSUCCESSUSER = "UPDATE_FORMSUCCESSUSER"
export const UPDATE_FORMSUCCESSUSER_SUCCESS = "UPDATE_FORMSUCCESSUSER_SUCCESS"

export const DELETE_DYNAMICFORMUSER = "DELETE_DYNAMICFORMUSER"
export const DELETE_DYNAMICFORMUSER_SUCCESS = "DELETE_DYNAMICFORMUSER_SUCCESS"

export const UPDATE_DYNAMICFORM2 = "UPDATE_DYNAMICFORM2"
export const UPDATE_DYNAMICFORM2_SUCCESS = "UPDATE_DYNAMICFORM2_SUCCESS"

export const GET_USERDYNAMICFORM_SUCCESS = "GET_USERDYNAMICFORM_SUCCESS"
export const GET_USERDYNAMICFORM = "GET_USERDYNAMICFORM"

export const DELETE_CLASSATTANCE = "DELETE_CLASSATTANCE"
export const DELETE_CLASSATTANCE_SUCCESS = "DELETE_CLASSATTANCE_SUCCESS"

export const DELETE_LOCATION = "DELETE_LOCATION"
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"

export const UPDATE_LOCATION = "UPDATE_LOCATION"
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS"

export const ADD_LOCATION = "ADD_LOCATION"
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS"

export const GET_LOCATION = "GET_LOCATION"
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS"


export const GET_ALLWORKSHOPMEGA = "GET_ALLWORKSHOPMEGA"
export const GET_ALLWORKSHOPMEGA_SUCCESS = "GET_ALLWORKSHOPMEGA_SUCCESS"

export const GET_TEACHERSESSIONMEGA = "GET_TEACHERSESSIONMEGA"
export const GET_TEACHERSESSIONMEGA_SUCCESS = "GET_TEACHERSESSIONMEGA_SUCCESS"

export const ADD_TEACHERWORKSHOP = "ADD_TEACHERWORKSHOP"
export const ADD_TEACHERWORKSHOP_SUCCESS = "ADD_TEACHERWORKSHOP_SUCCESS"


export const ADD_SLIDER_SUCCESS = "ADD_SLIDER_SUCCESS"

export const DELETE_LESSONSTIME = "DELETE_LESSONSTIME"
export const DELETE_LESSONSTIME_SUCCESS = "DELETE_LESSONSTIME_SUCCESS"

export const ADD_COURSEMEGA_SUCCESS = "ADD_COURSEMEGA_SUCCESS"

export const DELETE_WORKSHOP2_SUCCESS = "DELETE_WORKSHOP2_SUCCESS"
export const DELETE_WORKSHOP2 = "DELETE_WORKSHOP2"

export const ADD_WORKSHOP2_SUCCESS = "ADD_WORKSHOP2_SUCCESS"

export const DELETE_WORKSHOPSESSION = "DELETE_WORKSHOPSESSION"
export const DELETE_WORKSHOPSESSION_SUCCESS = "DELETE_WORKSHOPSESSION_SUCCESS"

export const ADD_APPSETTING_SUCCESS = "ADD_APPSETTING_SUCCESS"

export const ADD_DYNAMIC_FORM3 = "ADD_DYNAMIC_FORM3"
export const ADD_DYNAMICFORM_SUCCESS3 = "ADD_DYNAMICFORM_SUCCESS3"

export const ADD_DYNAMIC_FORM2 = "ADD_DYNAMIC_FORM2"
export const ADD_DYNAMICFORM_SUCCESS2 = "ADD_DYNAMICFORM_SUCCESS2"

export const DELETE_USERTYPE = "DELETE_USERTYPE"
export const DELETE_USERTYPE_SUCCESS = "DELETE_USERTYPE_SUCCESS"

export const ADD_AUTH = "ADD_AUTH"
export const ADD_AUTH_SUCCESS = "ADD_AUTH_SUCCESS"

export const UPDATE_SUBPROJECT = "UPDATE_SUBPROJECT"
export const UPDATE_SUBPROJECT_SUCCESS = "UPDATE_SUBPROJECT_SUCCESS"

export const ADD_SUBPROJECT = "ADD_SUBPROJECT"
export const ADD_SUBPROJECT_SUCCESS = "ADD_SUBPROJECT_SUCCESS"

export const DELETE_SUBPROJECT = "DELETE_SUBPROJECT"
export const DELETE_SUBPROJECT_SUCCESS = "DELETE_SUBPROJECT_SUCCESS"

export const DELETE_TEACHER = "DELETE_TEACHER"
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS"


export const ADD_NEWTEACHER = "ADD_NEWTEACHER"
export const ADD_NEWTEACHER_SUCCESS = "ADD_NEWTEACHER_SUCCESS"

export const ADD_SUBDOMAIN_SUCCESS = "ADD_SUBDOMAIN_SUCCESS"

export const DELETE_SUBDOMAIN = "DELETE_SUBDOMAIN"
export const DELETE_SUBDOMAIN_SUCCESS = "DELETE_SUBDOMAIN_SUCCESS"

export const DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const DELETE_DYNAMICFORMEXTRAEXTRA = "DELETE_DYNAMICFORMEXTRAEXTRA"

export const DELETE_DYNAMICFORM = "DELETE_DYNAMICFORM"
export const DELETE_DYNAMICFORM_SUCCESS = "DELETE_DYNAMICFORM_SUCCESS"

export const DELETE_DYNAMICFORMEXTRA = "DELETE_DYNAMICFORMEXTRA"
export const DELETE_DYNAMICFORMEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRA_SUCCESS"

export const DELETE_COURSESCHEDULES = "DELETE_COURSESCHEDULES"
export const DELETE_COURSESCHEDULES_SUCCESS = "DELETE_COURSESCHEDULES_SUCCESS"

export const ADD_GALLERYMULTIPLE = "ADD_GALLERYMULTIPLE"
export const ADD_GALLERYMULTIPLE_SUCCESS = "ADD_GALLERYMULTIPLE_SUCCESS"

export const DELETE_GALLERY = "DELETE_GALLERY"
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"

export const ADD_GALLERY = "ADD_GALLERY"
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS"

export const DELETE_REFERANCES = "DELETE_REFERANCES"
export const DELETE_REFERANCES_SUCCESS = "DELETE_REFERANCES_SUCCESS"

export const DELETE_POPUP = "DELETE_POPUP"
export const DELETE_POPUP_SUCCESS = "DELETE_POPUP_SUCCESS"

export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS"
export const DELETE_SLIDER = "DELETE_SLIDER"
export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS"
export const DELETE_MEGA_SUCCESS = "DELETE_MEGA_SUCCESS"
export const DELETE_MEGA = "DELETE_MEGA"
export const ADD_MEGA_SUCCESS = "ADD_MEGA_SUCCESS"

export const GET_DYNAMICFORM_EXTRA_EXTRA = "GET_DYNAMICFORM_EXTRA_EXTRA"
export const GET_DYNAMICFORMEXTRAEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const GET_DYNAMICFORMEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRA_SUCCESS"
export const GET_DYNAMICFORM_EXTRA = "GET_DYNAMICFORM_EXTRA"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES = "GET_CLASSES"
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"


export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
export const GET_GALLERY = "GET_GALLERY"

export const UPDATE_WORKSHOP2_SUCCESS = "UPDATE_WORKSHOP2_SUCCESS"

export const UPDATE_SUBDOMAIN = "UPDATE_SUBDOMAIN"

export const UPDATE_NEWS = "UPDATE_NEWS"

export const UPDATE_PROJECT = "UPDATE_PROJECT"

export const UPDATE_DYNAMICFORM = "UPDATE_DYNAMICFORM"
export const GET_DYNAMICFORM_SUCCESS = "GET_DYNAMICFORM_SUCCESS"
export const UPDATE_DYNAMICFORM_SUCCESS = "UPDATE_DYNAMICFORM_SUCCESS"


export const ADD_DYNAMICFORM_SUCCESS = "ADD_DYNAMICFORM_SUCCESS"

export const UPDATE_SLIDER = "UPDATE_SLIDER"
export const UPDATE_SUCCESS_PROJECT = "UPDATE_SUCCESS_PROJECT"
export const UPDATE_SUCCESS_GALLERY = "UPDATE_SUCCESS_GALLERY"

export const UPDATE_COURSESCHEDULES = "UPDATE_COURSESCHEDULES"
export const UPDATE_COURSESCHEDULES_SUCCESS = "UPDATE_COURSESCHEDULES_SUCCESS"

export const UPDATE_SUBDOMAIN_SUCCESS = "UPDATE_SUBDOMAIN_SUCCESS"
export const UPDATE_WORKSHOPSESSION_SUCCESS = "UPDATE_WORKSHOPSESSION_SUCCESS"
export const UPDATE_SUCCESS_COURSESCHEDULES = "UPDATE_SUCCESS_COURSESCHEDULES"
export const UPDATE_SUCCESS_TEACHER = "UPDATE_SUCCESS_TEACHER"
export const UPDATE_SUCCESS_SLIDER = "UPDATE_SUCCESS_SLIDER"
export const UPDATE_SUCCESS_NEWS = "UPDATE_SUCCESS_NEWS"
export const UPDATE_SUCCESS_MEGA = "UPDATE_SUCCESS_MEGA"
export const UPDATE_MEGA = "UPDATE_MEGA"
export const UPDATE_WORKSHOP2 = "UPDATE_WORKSHOP2"
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const UPDATE_REFERANCES = "UPDATE_REFERANCES"
export const UPDATE_REFERANCES_SUCCESS = "UPDATE_REFERANCES_SUCCESS"

export const GET_TEACHER_WORKSHOP = "GET_TEACHER_WORKSHOP"
export const GET_WORKSHOP2_SUCCESS = "GET_WORKSHOP2_SUCCESS"
export const GET_SUBPROJECT_SUCCESS = "GET_SUBPROJECT_SUCCESS"

export const GET_AUTHORITY = "GET_AUTHORITY"
export const GET_CONTACT = "GET_CONTACT"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS"
export const UPDATE_AUTH = "UPDATE_AUTH"
export const GET_WORKSHOP2 = "GET_WORKSHOP2"
export const GET_REFERANCES = "GET_REFERANCES"
export const GET_REFERANCES_SUCCESS = "GET_REFERANCES_SUCCESS"
export const ADD_REFERANCES = "ADD_REFERANCES"
export const ADD_REFERANCES_SUCCESS = "ADD_REFERANCES_SUCCESS"
export const ADD_DYNAMIC_FORM = "ADD_DYNAMIC_FORM"


export const GET_AUTHUPDATE_SUCCESS = "GET_AUTHUPDATE_SUCCESS"
export const GET_SUBPROJECT = "GET_SUBPROJECT"
export const GET_CLASSATTENDANCE = "GET_CLASSATTENDANCE"
export const GET_CLASSATTENDANCE_SUCCESS = "GET_CLASSATTENDANCE_SUCCESS"

export const UPDATE_LESSONS_TIME = "UPDATE_LESSONS_TIME"
export const ADD_LESSONSTIME_SUCCESS = "ADD_LESSONSTIME_SUCCESS"
export const ADD_LESSONS_TIME = "ADD_LESSONS_TIME"
export const ADD_WORKSHOP2 = "ADD_WORKSHOP2"
export const ADD_APPSETTING = "ADD_APPSETTING"
export const ADD_WORKSHOPSUBDOMAIN = "ADD_WORKSHOPSUBDOMAIN"
export const ADD_COURSE = "ADD_COURSE"
export const ADD_MEGA = "ADD_MEGA"
export const ADD_NEWS = "ADD_NEWS"
export const ADD_PROJECT = "ADD_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_SLIDER = "ADD_SLIDER"
export const ADD_POPUP = "ADD_POPUP"
export const ADD_POPUP_SUCCESS = "ADD_POPUP_SUCCESS"

export const UPDATE_TEACHER = "UPDATE_TEACHER"
export const UPDATE_WORKSHOPSESSION = "UPDATE_WORKSHOPSESSION"
export const UPDATE_POPUP = "UPDATE_POPUP"
export const GET_POPUP_SUCCESS = "GET_POPUP_SUCCESS"
export const UPDATE_POPUP_SUCCESS = "UPDATE_POPUP_SUCCESS"
export const GET_TEACHERWORKSHOP_SUCCESS = "GET_TEACHERWORKSHOP_SUCCESS"
export const UPDATE_LESSONSTIME_SUCCESS = "UPDATE_LESSONSTIME_SUCCESS"

export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS"
export const GET_WORKSHOPSESSION_SUCCESS = "GET_WORKSHOPSESSION_SUCCESS"
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_MEGA_SUCCESS = "GET_MEGA_SUCCESS"
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS"
export const GET_DYNAMIC_SUCCESS = "GET_DYNAMIC_SUCCESS"

/* POPUP */
export const GET_POPUP = "GET_POPUP"
export const GET_WORKSHOP = "GET_WORKSHOP"
export const GET_CourseSchedulesMega = "GET_CourseSchedulesMega"
export const GET_WorkshopSessionMega = "GET_WorkshopSessionMega"
export const GET_TeacherMega = "GET_TeacherMega"
export const GET_EVENT = "GET_EVENT"
export const GET_DYNAMICFORM = "GET_DYNAMICFORM"
export const GET_SLIDER = "GET_SLIDER"
export const GET_PROJECTSNEW = "GET_PROJECTSNEW"
export const GET_NEWS = "GET_NEWS"
export const GET_MEGA = "GET_MEGA"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"


/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
