import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
  deletePdf as onDeletePdf,
  addPdf as onAddPdf,
  updatePdf as onUpdatePdf,
  getPdf as onGetPdf,
} from "store/contacts/actions";

import {
  Date,
  LinkA,
  Name,
  Status
}
  from "./EcommerceOrderCol";
  import 'react-dropzone-uploader/dist/styles.css'
  import Dropzone from 'react-dropzone-uploader';

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";

function EcommerceOrder() {

  //meta title
  document.title = "Pdf | Mega - Admin Paneli";

  const [getIsActive, setIsActive] = useState(false);
  const [getFileNameBanner, setFileNameBanner] = useState(null);
  const  [getSliderPhoto, setSliderPhoto] = useState(null);

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  // handle the status of the file upload
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameBanner(result.file.filename)

        }
      }
    }
  }


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditSlider, setEditSlider] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getEditSlider && getEditSlider.PDF_Name) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
          var localDate = moment().local();
        
        const updateSlider = {
          ID: order ? order.ID : 0,
          PDF_Link: getFileNameBanner,
          Created_DateTime: localDate,
          PDF_Name : values.title,
          IsActive:getIsActive
        };
        // update order
        dispatch(onUpdatePdf(updateSlider));
        validation.resetForm();
      } else {
          var localDate = moment().local();
        
        const newOrder = {
          PDF_Link:getFileNameBanner,
          PDF_Name : values["title"],
          IsActive:getIsActive
        };
        // save new order
        dispatch(onAddPdf(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { pdf } = useSelector(state => ({
    pdf: state.contacts.pdf,
  }));

  useEffect(() => {
   if (pdf && !pdf.length) {
      dispatch(onGetPdf());
    }
  }, [dispatch, pdf]);

  useEffect(() => {
    setOrderList(pdf);
  }, [pdf]);

  useEffect(() => {
    if (!isEmpty(pdf) && !!isEdit) {
      setOrderList(pdf);
      setIsEdit(false);
    }
  }, [pdf]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setEditSlider(order)
    setOrder(order);
    setIsEdit(true);

    setFileNameBanner(order.PDF_Link)
    setIsActive(order.IsActive)
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeletePdf(order.ID));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'BAŞLIK',
        accessor: 'PDF_Name',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        Header: 'Pdf Link',
        accessor: 'PDF_Link',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <LinkA {...cellProps} />;
        }
      },

      {
        Header: 'Eklenme Zamanı',
        accessor: 'Created_DateTime',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'Aktif Mi?',
        accessor: 'IsActive',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },

      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Pdf" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={pdf}
                    isGlobalFilter={true}
                    isAddPdf={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Slider Düzenle" : "Slider Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >

<div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
                  
                 

                <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Banner Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusBanner}
          maxFiles={1}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="application/pdf" // Sadece PDF dosyalarını kabul eder
          />
                  <small className="text-muted">Sadece PDF dosyalarını yükleyebilirsiniz.</small>

                          </Col>
                </Row>

                <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;